import 'core/Utils/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './UI/App';
import reportWebVitals from './reportWebVitals';
import {LoaderContainer, MessageModalContainer} from "core/UI";


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
      <LoaderContainer>
        <MessageModalContainer>
          <App/>
        </MessageModalContainer>
      </LoaderContainer>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
